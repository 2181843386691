import React from 'react'
import p2 from "../images/p2.jpg"

const Invitaion = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h1><b>Baic Important Points of Invitaion</b></h1><br />
                    <b> 1. </b>Note:- Invitaion Must only in 2 Minutes <br />
                    <b> 2. </b>5 Baby Steps of Invitaion<br />..........Intro<br/>............Need <br/>..............Value<br/>................No Exist Cost<br/>..................Create Urgency ( Limited Time , Limited offer , Limited Peoples )<br/>
                    <b> 3. </b>Mental Exercise For Invitation<br/>...........1. Feel Confidence 2. Giving Atitude 3. Excitement<br />
                    <b>4. </b>Confidence se Baat Kijiye<br />
                    
                   
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{flexDirection:"column"}} >
                <h2 style={{ lineHeight: "2em" }}> <b>Written Page Invitaion</b><br />
                </h2>
                <img src={p2} class="img-fluid img-prospecting" alt="online"></img>
            </div>
        </div>
    )
}

export default Invitaion