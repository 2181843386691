import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaLock, FaUser } from 'react-icons/fa'
import "../components/login/login2.css"
import { NavLink } from 'react-router-dom'
// import Spinner from './Spinner'
// import loading from "./images/loading.gif" 
    
const Login = (props) => {
    const [credentials, setCredentials] = useState({ email: "", password: "" })
    let navigate = useNavigate(); 
    const handleSubmit = async (e) => { 
        e.preventDefault();
        const response = await fetch("https://inotebook-gqci.onrender.com/api/Auth/login", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify({ email: credentials.email, password: credentials.password }) 
        }); 
        const json = await response.json() 
        console.log(json) 
        if (json.success) { 
            //save the authtoken and redirect 
            // {loading && <Spinner />}  
            localStorage.setItem('token', json.authtoken);  
            
            props.showAlert("Logged In Successfully !", "success") 
            navigate("/Home");  
            
        }
        else {  
            props.showAlert("Access Denied ! Contact to Our Team", "Danger") 
        } 
    } 

    const onchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value }) 
    }

    return ( 
        // <form onSubmit={handleSubmit}> 
        //     <div className="mb-3"> 
        //         <label htmlFor="email" className="form-label"> Enter Your Email </label> 
        //         <input type="email" className="form-control" value={credentials.email} onChange={onchange} name='email' id="email" aria-describedby="emailHelp" />
        //         <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> 
        //     </div> 
        //     <div className="mb-3"> 
        //         <label htmlFor="password" className="form-label">Password</label> 
        //         <input type="password" className="form-control" value={credentials.password} onChange={onchange} name="password" id="password" />
        //     </div>

        //     <button type="submit" className="btn btn-primary" >Submit</button>
        // </form>


        <div className='bg'>
            <div className='container-nb' style={{ backgroundColor: "tranparent", border: "display-block", height: "133vh" }}>
                <div className='form-box2' style={{ color: "white" }}>
                    <form action='' method='' style={{ marginTop: "" }} onSubmit={handleSubmit}>
                        <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Login Page</h1>
                        <div className='input-box2 mt-5'>
                            <input type='number' placeholder="Your Vestige Id" value={credentials.email} onChange={onchange} name='email' id="email" required />
                            {/* <FaUser className='icon2' />  */}
                        </div>
                        <div className='input-box2'> 
                            <input type='password' placeholder='Password' value={credentials.password} onChange={onchange} name="password" id="password" required />
                            <FaLock className='icon2' />
                        </div>

                        {/* <div className='remember-forgot2'>
                            <label><input type='checkbox' /> Remember Me</label><a href='#' style={{ color: "#89ffae" }}> Forgot Password</a>
                        </div>  */} 
                        {/* <label> */}
                            <button className='btn0' type='submit'>Login</button> 
                        {/* </label> */} 
                        {/* <div className='register-link2'>
                            <p>Don't have an account? <NavLink to="/Signup" style={{ color: "#80ff32" }}>Signup!</NavLink></p> 
                        </div> */}

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login