import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import team from "./images/team.jpg"
import "./css/profile.css"
import chandan from "./images/chandan.jpg"
import { NavLink } from 'react-router-dom'
import hand from "./images/hand.jpg"
import jc from "./images/jc.jpg"
import team2 from "./images/team2.jpg"
import Contact from './Contact';
import wts from "./images/wts.jpeg"
import Services from './Services';
import team1 from "./images/team1.jpg"

const Profile = (props) => {

    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            //   alert = "logged in";
            navigate("/Home");

        }
        else {
            // getProfile()
        }

    }, [])
    const showAlert = props
    return (
        <div>
            <div className='wts'>

                <NavLink to="https://wa.link/c70quf" style={{ color: "blue" }}>  <img src={wts} class="img-fluid" alt="..." /></NavLink>

            </div>
            <div className='fixed'>
                <img src={chandan} class="img-fluid sticy-top img-chandan" alt="..." />
            </div>
            <div class="card text-bg-primary mb-3" >
                <div class="card-header"><h3>Myself Chandan Rana from TSS </h3>
                </div>
                <div class="card-body">
                    <p class="card-text" style={{ alignItems: "center", justifyContent: "center", display: "flex" }}><h6><br /><br /><b>I am Professional In Computer Science & A Certified Web Developer </b></h6></p>

                </div>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" ><h5><b style={{ lineHeight: "1.5em" }}>** Hum offer Karte hai , Ek Best Business jo Aap Apne Work ya Study Ke Saath Start Kar Sakte Hai<br />....................With Sonu Sharma Sir (Motivational Speaker)</b></h5><br />
                </h2>
            </div>

            <div class="card text-bg-primary mb-3 center-hand" >
                {/* <img src={hand} class="img-fluid sticy-top img-hand" alt="..." /> */}
                <div className="card-header fs"><h2 style={{ fontSize: "1.2em" }}>Click on <b>Start </b>to chat</h2>
                    <NavLink to="https://wa.link/c70quf " style={{ color: "#ffffff" }}>...Start...</NavLink>
                </div>


            </div>
            <div className='container-nb'>
                <div id="carouselExampleDark" className="carousel carousel-dark slide mt-4" data-bs-ride="carousel">
                    <div className="carousel-indicators" >
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner" style={{ boxShadow: "-17px 17px 18px white" }}>
                        <div className="carousel-item active" data-bs-interval="2000">
                            <img src={team2} className="d-block w-100 img-profile" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 style={{ color: "white" }}>First slide label</h5>
                                {/* <p style={{ color: "white" }}>Some representative placeholder content for the first slide.</p> */}
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={jc} className="d-block w-100 img-profile" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 style={{ color: "white" }}>Second slide label</h5>
                                {/* <p style={{ color: "white" }}>Some representative placeholder content for the second slide.</p> */}
                            </div>
                        </div>
                       
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>


            </div>
            <div class="card text-bg-info mt-5 mb-3">
                <div class="card-header"><h3>Biography of Mr. Chandan Rana </h3></div>
                <div class="card-body">
                    <p class="card-text "><h6 className='bada'>Basically I Belong to Jharkhand From Hazaribag City And Started This Journey Along With My Study , Other Students Wastes Their Time in This Golden Age (18-25) or They do Nothing But I Realize to Help People Make Money Along With Myself<br /><br /> The Question is Simple That If I Can Do , <br />Then You Can Also Definitely do , Just Believe in Yourself.. & Achieve it</h6></p>
                </div>
            </div>
            <Services />
            <Contact />
        </div>
    )
}
export default Profile