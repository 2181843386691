import React from 'react'
import { NavLink } from 'react-router-dom'
const crmedia = () => {
  return (
    <div>
         <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "12vh"}} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" }}><h3><b>About,  Chandan Rana Social Media</b></h3><br />

                </h2> 
            </div> 

            <div className='rowside' style={{ display: "flex" }}>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Instagram Search, Chandan Rana</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Insta Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.instagram.com/office.chandanrana_/" style={{ backgroundColor: "blue",color:"white"}}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>YouTube Search, Chandan Rana</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>YouTube Id </b>- ChandanranaUNIQUE<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.youtube.com/@ChandanranaUNIQUE" style={{ backgroundColor: "blue",color:"white" }}> <b>YouTube Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div> 
                <div class="shadow-lg p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Facebook Search, Chandan Rana</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Facebook Id </b>- Chandan Rana<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.facebook.com/profile.php?id=100034922888706" style={{ backgroundColor: "blue",color:"white" }}> <b>FB Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div>
                <hr />
            </div>
    </div>
  )
}

export default crmedia