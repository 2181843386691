import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import { NavLink } from "react-router-dom";

const Teammedia = () => {

    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])

  return (
    <div>
         <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "10vh"}} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em",color:"blue"  }}><h3><b>About Our Personal Team</b></h3><br />

                </h2>
            </div>



            <div className='rowside' style={{ display: "flex" }}>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded" > 
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Ms. Sarita</b></h4><br /> 
                        <b> 1. </b><b style={{color:"black"}}>Insta Id </b>- office.chandanrana_<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded" >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Ms. Rekha</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>YouTube Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="shadow-lg p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>M/s Kaushalya</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Facebook Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div> 
                <hr />
            </div>

            <div className='rowside' style={{ display: "flex" }}>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded" > 
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Mr. Mithlesh</b></h4><br /> 
                        <b> 1. </b><b style={{color:"black"}}>Insta Id </b>- office.chandanrana_<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded" >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Ms. Sanjana</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>YouTube Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="shadow-lg p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Mr. Chanda 
                        rana</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Facebook Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="" style={{ backgroundColor: "blue",color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div> 
                <hr />
            </div>
    </div>
  )
}

export default Teammedia