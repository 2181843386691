import "../components/css/product.css"
// import { a } from 'react-router-dom'
import logo3 from "./images/logo3.jpg"
import doctor from "./images/doctor.webp"
import fit from "./images/fit.jpeg"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import { NavLink } from "react-router-dom"
import "./css/health.css"

// import Ganoderma1 from "../images/ganoderma1.jpg"

const HealthSolution = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <>
            <div className='container-product'>
                <img src={doctor} alt='doctor' />

            </div>




            <h1 className="mt-5" style={{ marginBottom: '2em', textAlign: "center" }}>Supplement Knowledge is the key to success</h1>
            <form className="d-flex">
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success" type="submit">Search</button>
            </form>



            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#hair' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Hair Growth Problem</b></h5>
                    </div>
                </a>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#motapa' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Cholestrol Kam ya Motapa Kam Karna</b></h5>
                    </div>
                </a>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#knee' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Knee Problem Best Supplement</b></h5>
                    </div>
                </a>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#kamar' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Kamar Se Feet Tak (Sciatica , Pain)</b></h5>
                    </div>
                </a>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#kidney' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Kidney Stone</b></h5>
                    </div>
                </a>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <a href='#liquor' style={{ color: "blue" }} >
                    <div className="box1-product1" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><h5><b> Liquor Se Freedom</b></h5>
                    </div>
                </a>
            </div>





            <hr />

            <div className='flex' id='hair'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Hair Growth Problem</h2>
                    <p>\_________ Best Supplement For Hair Growth , Vestige Provides For you
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) Noni Supplement <br />(2.) Hair Skin & Nail Supplement <br />
                        (3.)Amla &b Alovera <br />(4.)Hair oil & Anti-Hairfall Shampoo <br />
                    </p>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4>
                    </p>
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />


            <div className='flex' id='knee'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Knee Pain Problem</h2>
                    <p>\_________ Best Supplement For Knee Pain Problem
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) Noni Supplement <br />(2.) Flax oil <br />
                        (3.) Calcium <br />(4.) Glucosamine <br />
                    </p>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4>
                    </p>
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='motapa'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Motapa Kam Karna</h2>
                    <p>\_________ Best Supplement For Weight Loss
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) Veslim Shake <br />(2.) Veslim Tea <br />
                        (3.)Veslim Capsules <br />
                    </p>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4>
                    </p>
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />

            <div className='flex' id='kamar'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Kamar Pain Problem</h2>
                    <p>\_________ Best Supplement Kamar Pain
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) L - Arginine  <br />(2.) Noni<br />
                        (3.) .... <br />(4.) .... <br />
                    </p>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4>
                    </p>
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='kidney'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Kidney Stone Problem </h2>
                    <p>\_________ Best Supplement For Kidney Stone Problem
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) Alobvera <br />(2.) Ganoderma <br />
                        (3.) Flax oil <br />(4.) Cranberry <br /> (5. ) Foot Patch
                    </p>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4>
                    </p>
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='liquor'>
                <div className='mid-health'>
                    <div className='product_images-health' style={{ display: "flex" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Saraab Se Chhutkara</h2>
                    <p>\_________ Best Supplement From Liquor Freedom
                    </p>
                    <h4><b>100% Result</b></h4>
                    <p> (1.) Noni Supplement <br />(2.) Foot Patches  <br />
                        (3.) Alovera <br />(4.) Liver Health <br /> (5.) Flax Oil <br/>(6.) Ganoderma <br/> (7.) Gano Tooth Paste
                    </p>
                   

                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="https://youtu.be/rylIM9VTWxw?si=ydGPkRVUptvwJxjU" style={{ color: "blue" }}><h5> <b>Complete Knowledge Video ( Mitali joshi)</b></h5></NavLink>
                    </div>
                    <p className='product-data-price'>
                        <h4><b>Feedback Video By People</b></h4> 
                    </p>

                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                        <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                        <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                    </div>
                </div>
            </div> <hr style={{ marginTop: "5em" }} />

        </>
    )
}

export default HealthSolution