import React from 'react'
import { NavLink } from 'react-router-dom'

const Services = () => {
    return (
        <>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{ height: "11vh",backgroundColor: "darkslategray" }} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em", textAlign:"center" , color:"white"}}><h4><b>About Sonu Sharma Sir Profile</b></h4><br />

                </h2>
            </div>

            <div className='rowside' style={{ display: "flex" }}> 
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded cntr">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue" }}><h5 style={{color:"rgb(0 25 97)"}}><b>Motivational Speaker, Corporate Trainer & Business Coach</b></h5><br />
                        <b> 1. </b><b style={{color:"black"}}>Name:- </b> Sonu Sharma<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="/ssmedia" style={{ backgroundColor: "blue" , color:"white" }}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2> 
                </div> 
                
                <hr />
            </div>
 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "12vh" , backgroundColor: "darkslategray"}} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" , textAlign:"center",color:"white" }}><h4><b>About Chandan Rana Profile</b></h4><br />

                </h2> 
            </div> 
 
            <div className='rowside' style={{ display: "flex" }}> 
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded cntr"> 
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h5 style={{color:"rgb(0 25 97)"}}><b>Web Developer & Core Leader of Sonu Sir</b></h5><br />
                        <b> 1. </b><b style={{color:"black"}}>Name:- </b> Chandan Rana<br />
                        <b> 2. </b><button className='primary'><NavLink to="/CRmedia" style={{ backgroundColor: "blue",color:"white"}}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2>
                </div>
              
                <hr /> 
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "10vh",backgroundColor: "darkslategray"}} > 
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em", textAlign:"center"  ,color:"white" }}><h4><b>About Our Team Pofile</b></h4><br />

                </h2>
            </div>


            <div className='rowside' style={{ display: "flex" }}>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded cntr">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", textAlign:"center"   }}><h5 style={{color:"rgb(0 25 97)"}}><b>Dynamic Personality & Leading Peoples</b></h5><br />
                        <b> 1. </b><b style={{color:"black"}}>Name:- </b> Leading Leaders<br />
                        <b> 2. </b><button className='primary'><NavLink to="/Teammedia" style={{ backgroundColor: "blue",color:"white"}}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2>
                </div>
              
                <hr />
            </div>
        </>
    )
}

export default Services     