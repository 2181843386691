import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';

const Navbar = () => {
    let navigate = useNavigate();
    const handleLogout = ()=>{
        localStorage.removeItem('token');
        navigate("/login")
    }
    let location = useLocation();
    useEffect(() => {
      console.log(location.pathname);
    }, [location]);
    
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="#">Team Chandan Rana</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{display:"flex"}}>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/"? "active": ""}`} aria-current="page" to="/Home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/"? "active": ""}`} aria-current="page" to="/Teamdata">Set Notes</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/Product"? "active": ""}`} to="/Product">Supplement</Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/Agri"? "active": ""}`} to="/Agri">Agriculture</Link>
                        </li>

                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/HealthSolution"? "active": ""}`} to="/HealthSolution">Health Problem</Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/Work"? "active": ""}`} to="/Work">Online Work</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/profile"? "active": ""}`} to="/Profile">Profile</Link>
                        </li>
                        */}
                        
                       
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/about"? "active": ""}`} to="/About">About us</Link>
                        </li>
                       
                    </ul>

                    
                    
                    {!localStorage.getItem('token')?<form className="d-flex">
                    <Link className="btn btn-primary mx-1" to="/login" role="button">Login</Link>
                    {/* <Link class="btn btn-primary mx-1" to="/signup" role="button">Signup</Link> */}
                    </form>: <button className='btn btn-primary' onClick={handleLogout}> Logout </button> }
                </div>
            </div>
        </nav>
    )
}

export default Navbar