import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { FaSignalMessenger } from 'react-icons/fa6'
import Spinner from './Spinner'
import loading from "./images/loading.gif"
import { GoAlert } from 'react-icons/go'

const Signup = (props) => {

    const [credentials, setCredentials] = useState({ name: "", email: "", password: "", cpassword: "" })
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password } = credentials;
        const response = await fetch("https://inotebook-gqci.onrender.com/api/Auth/createuser", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, password })
        });
        const json = await response.json()
        console.log(json)
        if (json.success) {
            //save the authtoken and redirect 
            localStorage.setItem('token', json.authtoken);
            { loading && <Spinner /> } 
            // navigate("/"); 
            props.showAlert("Successfully ! Created Your Account ", "success") 
            
            // alert("Great ! Id Created Successfull ", "success") 

        }
        else {
            props.showAlert("Entered Duplicate Vestige ID", "Danger") 


            // alert("Entered Duplicate Vestige ID", "Danger")


        }
    }
    const onchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    return (
        // <form onSubmit={handleSubmit}>
        //     <div className="mb-3"> 
        //         <label htmlFor="name" className="form-label">Name</label>
        //         <input type="text" className="form-control" id="name" name='name' onChange={onchange} aria-describedby="emailHelp" />
        //     </div>
        //     <div className="mb-3">
        //         <label htmlFor="email" className="form-label">Email address</label>
        //         <input type="email" className="form-control" id="email" name='email' onChange={onchange} aria-describedby="emailHelp" />
        //         <div id="email" className="form-text">We'll never share your email with anyone else.</div>
        //     </div>
        //     <div className="mb-3">
        //         <label htmlFor="password" className="form-label">Password</label>
        //         <input type="password" className="form-control" id="password" name='password' onChange={onchange} minLength={5} required/>
        //     </div>
        //     <div className="mb-3">
        //         <label htmlFor="cpassword" className="form-label">Confirm Password</label> 
        //         <input type="password" className="form-control" id="cpassword" name='cpassword' onChange={onchange} minLength={5} required/>
        //     </div>

        //     <button type="submit" className="btn btn-primary">Submit</button>
        // </form>





        <div className='bg'>

            <div className='container-nb' style={{ backgroundColor: "tranparent", border: "display-block" }}>
                <div className='form-box2-reg' style={{ color: "white" }}>
                    <form action='' onSubmit={handleSubmit} loader={<Spinner />} >
                        <h1 className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ul>Registeration</ul></h1>

                        <div className='input-box2'>
                            <input type='text' placeholder="Your Name" id="name" name='name' onChange={onchange} required />
                            <FaSignalMessenger className='icon2' />
                        </div>

                        <div className='input-box2'>
                            <input type='number' placeholder="Your Vestige Id No." id="email" name='email' onChange={onchange} required />
                            {/* <FaEnvelope className='icon2' />  */}
                        </div>

                        <div className='input-box2'>
                            <input type='password' placeholder='Enter Team id Again' id="password" name='password' onChange={onchange} minLength={5} required />
                            <FaLock className='icon2' /> 
                        </div> 
                        <div className='input-box2'>
                            <input type='password' placeholder=' Confirm id Again' id="cpassword" name='cpassword' onChange={onchange} minLength={5} required />
                            <FaLock className='icon2' /> 
                        </div>

                        {/* <div className='remember-forgot2'>
                            <label><input type='checkbox' required /> I agree to the terms  & conditions</label>
                        </div> */}
                        <button className='btn0' type='submit'>Signup</button>
                        <div className='register-link2'>
                            <p>Don't have an account? <NavLink to="/login" style={{ color: "#80ff32" }}>Login</NavLink></p>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    )
}

export default Signup