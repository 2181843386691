import listpeople from "../images/listpeople.png"
import "../css/online.css"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../../context/notes/noteContext';
import { NavLink } from "react-router-dom";

const Online = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <>
            <img src={listpeople} class="img-fluid img-online" alt="online"></img>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <NavLink to="/Namelist" style={{ color: "black" }}><b>Make Your Name List</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Dreamlist" style={{ color: "black" }}> <b>Make Your Goal List</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/zpFpLj5-vKY?si=g-r3v4OI_WbmpBbt" style={{ color: "black" }}> <b>Your 1st Traning</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/wFhHYizzcb8?feature=shared" style={{ color: "black" }}> <b>Now 2nd Traning</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/NDyq4k_6Iz0?feature=shared" style={{ color: "black" }}> <b>Your 3rd Traning </b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/JIj6n5ciMds?feature=shared" style={{ color: "black" }}> <b>Your 4th Traning</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/ooWzqTjeoeE?si=JZR8yyNdFDrQsU0W" style={{ color: "black" }}> <b>Your 5th Traning</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://www.youtube.com/live/4g2VOTS5Zwg?si=D9CSgZRTCKXIWB35" style={{ color: "black" }}> <b>Products Traning With Demo</b></NavLink>
            </div>
        </>
    )
}

export default Online