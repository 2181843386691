import React, { useContext } from 'react'
import noteContext from '../context/notes/noteContext';
import { useState } from 'react'; 

const AddNote = (props) => { 
    const context = useContext(noteContext); 
    const { addNote } = context; 
    const [note, setNote] = useState({ title: "", description: "", tag: "" }) 

    const handleClick = (e) => {
        e.preventDefault();
        addNote(note.title , note.description, note.tag);
        setNote({title: "", description: "", tag: "" })
        // props.showAlert("Updated Successfully " , "success")
    } 
    const onChange = (e) => { 
        setNote({ ...note, [e.target.name]: e.target.value }) 
    }
    return (
        <div className='container my-3'>
            <h2>Add Your Work Here</h2>
            <form className='container my-3'>
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Date & Your Name* </label> 
                    <input type="text" className="form-control" id="title" name="title" value={note.title} placeholder="1 Sep, Chandan" onChange={onChange}  minLength={5} required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">No. of Plans* , Follow Up* & Joining*</label> 
                    <input type='text' className="form-control" id="description" name="description"  value={note.description}rows="3" placeholder="5 Plan, 10 Follow up & 2 Joining" onChange={onChange} minLength={5} required></input>
                </div> 
               
                <div className="mb-3"> 
                    <label htmlFor="tag" className="form-label">Working Day :- Yes/No*</label> 
                    <input type='text' className="form-control" id="tag" name="tag" value={note.tag} rows="3" placeholder="" onChange={onChange} minLength={5} required></input>
                </div> 
                <button disabled={note.title.length<5 || note.description.length<5 } type='submit' className='btn btn-primary' onClick={handleClick}>Add Note</button>
            </form> 

            
        </div> 
    )
}

export default AddNote