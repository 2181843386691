import React from 'react'
import online from "./images/online.png"
import { NavLink } from 'react-router-dom'
import "./css/online.css"

const Work = () => {
    return (
        <>
            <img src={online} class="img-fluid img-online" alt="online"></img>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Warm" style={{ color: "blue", fontSize: "1.2em" }}><b>Warm Market <NavLink to="/Warm"> <button type="button" class="btn btn-primary " style={{marginTop:"-2em"}} >
                    click here 
                </button></NavLink></b></NavLink>
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Online" style={{ color: "blue", fontSize: "1.2em" }}><b>Cold Market <NavLink to="/Online"> <button type="button" class="btn btn-primary " style={{marginTop:"-2em"}} >
                    click here 
                </button></NavLink></b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center"> 
                <NavLink to="/Noteshere" style={{ color: "blue", fontSize: "1.2em" }}><b>Notes Here <NavLink to="/Noteshere"> <button type="button" class="btn btn-primary " style={{marginTop:"-2em"}} >
                    click here 
                </button></NavLink></b></NavLink>
            </div>
        </>
    )
}

export default Work