import React from 'react'
import { NavLink } from 'react-router-dom'

const ssmedia = () => {
  return (
    <div>
        <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{ height: "11vh" }} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" }}><h3><b>About,  Sonu Sharma Sir Social Media</b></h3><br />

                </h2>
            </div>

            <div className='rowside' style={{ display: "flex" }}>
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue" }}><h4 style={{color:"rgb(0 25 97)"}}><b>Instagram Search , Sonu Sharma</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Insta Id </b>- officesonusharma<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="https://www.instagram.com/officesonusharma/" style={{ backgroundColor: "blue" , color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div> 
                <div class="shadow-lg p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>YouTube Search, Sonu Sharma</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>YouTube Id </b>- SONUSHARMAMotivation<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.youtube.com/@SONUSHARMAMotivation" style={{ backgroundColor: "blue",color:"white" }}> <b>YouTube Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div> 
                <div class="shadow-lg p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"blue"  }}><h4 style={{color:"rgb(0 25 97)"}}><b>Facebook Search, Sonu Sharma</b></h4><br />
                        <b> 1. </b><b style={{color:"black"}}>Facebook Id </b>- Sonu Sharma<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.facebook.com/profile.php?id=100034922888706" style={{ backgroundColor: "blue",color:"white" }}> <b>FB Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div>
                <hr />
            </div>

    </div>
  )
}

export default ssmedia