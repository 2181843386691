import online from "../images/online.avif"
import "../css/online.css"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../../context/notes/noteContext';
import { NavLink } from "react-router-dom";

const Online = () => {
  const context = useContext(noteContext);
  let navigate = useNavigate();
  const { getNotes } = context;
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getNotes()
    }
    else {
      navigate("/login/");
    }

  }, [])


  return (
    <>
      <img src={online} class="img-fluid img-online" alt="online"></img>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="" style={{ color: "black" }}><h5><b>*** Cold Market Means That You Don't Know Those People </b></h5></NavLink>
      </div>
      <button className='webinar' type='submit'> <NavLink to="https://youtu.be/5MvQRoAe2Zs" style={{ color: "blue" }}><h5> <b>Presentation Link By Sonu Sir</b></h5></NavLink></button> 
      <button className='webinar' type='submit'> <NavLink to="https://youtu.be/e77xHzB2uK0?si=4iO4bIkR02FoJN8f" style={{ color: "blue" }}><h5> <b>10 Min Presentation Link</b></h5></NavLink></button> 

      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center mt-5">
        <NavLink to="" style={{ color: "black" }}> <h5><b>** Learn Only these 5 Skills </b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Prospecting" style={{ color: "blue" }}><h5> <b>(1.) Prospecting</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Invitaion" style={{ color: "blue" }}><h5> <b>(2.) Invitaion</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="" style={{ color: "blue" }}><h5> <b>(3.) Presentation</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Edification" style={{ color: "blue" }}><h5> <b>(4.) Edification</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="" style={{ color: "blue" }}><h5> <b>(5.) Follow Up</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="" style={{ color: "blue" }}> <h5><b>(6.) Closing</b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Master" style={{ color: "blue" }}><h5> <b>Go Master on Your Work</b></h5></NavLink>
      </div> 

      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="" style={{ color: "black" }}><b><h3>**3 Major & Most Important Skill in online</h3>  </b></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="" style={{ color: "black" }}> <h5><b>** Prospecting & Invitation </b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Prospecting" style={{ color: "black" }}> <h5><b>Edification & </b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Invitaion" style={{ color: "black" }}><h5> <b>Follow Up</b></h5></NavLink>
      </div>
    </>
  )
}

export default Online